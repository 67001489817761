import { EnumsMessageDistributionChannel } from '@lp-lib/api-service-client/public';

import { assertExhaustive } from '../../../utils/common';
import { EmailIcon2 } from '../../icons/EmailIcon';
import { SlackIcon } from '../../icons/SlackIcon';

export function MessageTemplateDistributionChannelIcon(props: {
  channel: EnumsMessageDistributionChannel | null | undefined;
  className?: string;
}) {
  const { channel, className } = props;

  switch (channel) {
    case EnumsMessageDistributionChannel.MessageDistributionChannelEmail:
      return <EmailIcon2 className={className} />;
    case EnumsMessageDistributionChannel.MessageDistributionChannelSlack:
    case null:
    case undefined:
      return <SlackIcon className={className} />;
    default:
      assertExhaustive(channel);
      return null;
  }
}
