import {
  EnumsMessageTriggerTimeOffsetDirection,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
} from '@lp-lib/api-service-client/public';

import { type MessageTriggerConfig } from '..';

export interface MessageTriggerTypeConfig {
  label: string;
  value: EnumsMessageTriggerType;
  makeDefault: (config: MessageTriggerConfig) => ModelsMessageTrigger;
}

export const MESSAGE_TRIGGER_TYPE_CONFIG: Record<
  EnumsMessageTriggerType,
  MessageTriggerTypeConfig
> = {
  [EnumsMessageTriggerType.MessageTriggerTypeTimeOffset]: {
    label: 'Time Offset',
    value: EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
    makeDefault: (config: MessageTriggerConfig) => ({
      type: EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
      timeOffset: {
        baseTime: config.startTime,
        days: 0,
        hours: 0,
        minutes: 0,
        direction:
          EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter,
      },
    }),
  },
  [EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset]: {
    label: 'Weekdays Offset',
    value: EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
    makeDefault: (config: MessageTriggerConfig) => ({
      type: EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
      weekdaysOffset: {
        baseTime: config.startTime,
        days: 0,
        hours: 0,
        minutes: 0,
        direction:
          EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter,
      },
    }),
  },
  [EnumsMessageTriggerType.MessageTriggerTypeEventDriven]: {
    label: 'Event Driven',
    value: EnumsMessageTriggerType.MessageTriggerTypeEventDriven,
    makeDefault: (config: MessageTriggerConfig) => ({
      type: EnumsMessageTriggerType.MessageTriggerTypeEventDriven,
      eventName: config.events?.[0],
    }),
  },
};
