import {
  type DtoMessageLogicV2,
  EnumsMessageCampaignType,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useSetQueryParam } from '../../../hooks/useQueryParam';
import { Modal } from '../../common/Modal';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { type MessageCampaignV2 } from '../Shared';
import { CancelOfferAcceptedLogicEditor } from './CancelOfferAcceptedLogicEditor';
import { MemberInvitedLogicEditor } from './MemberInvitedLogicEditor';
import { OnboardingDripLogicEditor } from './OnboardingDripLogicEditor';
import { OTPEventCompletedLogicEditor } from './OTPEventCompletedEditor';
import { OTPPurchasedLogicEditor } from './OTPPurchasedLogicEditor';
import { OTPStartedLogicEditor } from './OTPStartedLogicEditor';
import { PlaygroundLogicEditor } from './PlaygroundLogicEditor';
import { ProgramInstalledLogicEditor } from './ProgramInstalledLogicEditor';
import { SessionEndedLogicEditor } from './SessionEndedLogicEditor';
import { type MessageLogicEditorProps } from './Shared';
import { SubscriptionStartedLogicEditor } from './SubscriptionStartedLogicEditor';

export function MessageLogicEditorV2(props: MessageLogicEditorProps) {
  switch (props.campaign.type) {
    case EnumsMessageCampaignType.MessageCampaignTypePlayground:
      return <PlaygroundLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeOnboardingDrip:
      return <OnboardingDripLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeOtpStarted:
      return <OTPStartedLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeOtpPurchased:
      return <OTPPurchasedLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeOtpEventEnded:
      return <OTPEventCompletedLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeMemberInvited:
      return <MemberInvitedLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeProgramInstalled:
      return <ProgramInstalledLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeSessionEnded:
      return <SessionEndedLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeCancelOfferAccepted:
      return <CancelOfferAcceptedLogicEditor {...props} />;
    case EnumsMessageCampaignType.MessageCampaignTypeSubscriptionStarted:
      return <SubscriptionStartedLogicEditor {...props} />;
    default:
      throw new Error('Unknown campaign type');
  }
}

export function useTriggerMessageLogicEditorV2() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const setQueryParam = useSetQueryParam();

  return useLiveCallback(
    async (props: {
      campaign: MessageCampaignV2;
      logic?: DtoMessageLogicV2;
      onSubmit: (logic: DtoMessageLogicV2) => void;
    }) => {
      if (props.logic) {
        setQueryParam('logic-id', props.logic.id);
      }
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <Modal className='w-screen h-screen' borderStyle='none'>
            <ConfirmCancelModalProvider>
              <MessageLogicEditorV2
                campaign={props.campaign}
                logic={props.logic}
                onSubmit={(logic) => {
                  props.onSubmit(logic);
                  p.internalOnConfirm();
                }}
                onCancel={p.internalOnCancel}
              />
              <ConfirmCancelModalRoot />
            </ConfirmCancelModalProvider>
          </Modal>
        ),
      });
      setQueryParam('logic-id', null);
    }
  );
}
