import { FormProvider, useForm } from 'react-hook-form';

import { type DtoMessageLogicV2 } from '@lp-lib/api-service-client/public';

import { type MessageCampaignV2 } from '../Shared';
import { MessageTemplateFields } from '../Template';
import { MessageLogicEditorLayout } from './MessageLogicEditorLayout';
import { type MessageLogicFormData } from './Shared';
import { MessageLogicUtils } from './utils';

export function PlaygroundLogicEditor(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;
}) {
  const { campaign, logic, onCancel, onSubmit } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: MessageLogicUtils.MakeDefaultValues({
      logic,
    }),
  });

  return (
    <FormProvider {...form}>
      <MessageLogicEditorLayout
        campaign={campaign}
        logic={logic}
        onCancel={onCancel}
        onSubmit={onSubmit}
        template={
          <MessageTemplateFields
            vars={{
              recipientName: 'John',
              notificationSettingsLink:
                'https://lunapark.com/settings/notifications',
            }}
          />
        }
      />
    </FormProvider>
  );
}
