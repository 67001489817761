import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useRevalidator,
} from '@remix-run/react';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import {
  type DtoMessageLogicV2,
  EnumsMessageLogicStatus,
} from '@lp-lib/api-service-client/public';

import { ActionSheet } from '../components/ActionSheet';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../components/ConfirmCancelModalContext';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { DuplicateIcon } from '../components/icons/DuplicateIcon';
import { EditIcon } from '../components/icons/EditIcon';
import { useTriggerMessageLogicEditorV2 } from '../components/Message/Logics';
import { MessageTargetUtils } from '../components/Message/Target';
import { MessageTemplateDistributionChannelIcon } from '../components/Message/Template';
import { MessageTriggerUtils } from '../components/Message/Trigger';
import { SwitcherControlled } from '../components/Switcher';
import { getQueryParam } from '../hooks/useQueryParam';
import { AdminView } from '../pages/Admin/AdminView';
import { apiService } from '../services/api-service';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;

  const campaigns = (await apiService.message.queryMessageCampaigns()).data
    .campaigns;
  const campaign = campaigns.find((campaign) => campaign.id === id);
  if (!campaign) throw new Response('Not Found', { status: 404 });

  const logics = (
    await apiService.message.getLogicsByCampaign(campaign.type, campaign.id)
  ).data.logics;

  return {
    campaign,
    logics: logics.sort((a, b) => a.name.localeCompare(b.name)),
  };
}

export function MessageLogicMenu(props: {
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
}) {
  return (
    <ActionSheet
      btnSizingClassName='w-7.5 h-7.5'
      actions={[
        {
          kind: 'button',
          key: 'edit',
          text: 'Edit',
          icon: <EditIcon />,
          onClick: () => props.onEdit(),
        },
        {
          kind: 'button',
          key: 'duplicate',
          text: 'Duplicate',
          icon: <DuplicateIcon />,
          onClick: () => props.onDuplicate(),
        },
        {
          kind: 'button',
          key: 'delete',
          text: 'Delete',
          icon: <DeleteIcon />,
          className: 'text-red-500',
          onClick: () => props.onDelete(),
        },
      ]}
    />
  );
}

function MessageLogicsTable(props: {
  title?: string;
  titleAccessory?: React.ReactNode;
  logics: DtoMessageLogicV2[];
  onClick: (logic: DtoMessageLogicV2) => void;
  onActivate: (logic: DtoMessageLogicV2) => void;
  onDeactivate: (logic: DtoMessageLogicV2) => void;
  renderActionSheet?: (logic: DtoMessageLogicV2) => React.ReactNode;
}) {
  const {
    title = 'Logics',
    titleAccessory,
    logics,
    onClick,
    onActivate,
    onDeactivate,
    renderActionSheet,
  } = props;

  const { activeCount, totalCount } = useMemo(() => {
    const activeCount = logics.filter(
      (logic) =>
        logic.status === EnumsMessageLogicStatus.MessageLogicStatusActive
    ).length;
    const totalCount = logics.length;
    return { activeCount, totalCount };
  }, [logics]);
  return (
    <div className='w-full'>
      <div className='flex justify-between items-center'>
        <h2 className='text-2xl font-bold'>
          {title}{' '}
          <span className='text-icon-gray text-xl'>
            {`(${activeCount} active / ${totalCount} total)`}
          </span>
        </h2>
        {titleAccessory}
      </div>
      <table className='mt-4 w-full table-fixed border-collapse'>
        <thead>
          <tr>
            <th className='text-left font-bold pl-3 py-2 border-b border-t border-secondary'>
              Name
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Trigger
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Target
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Status
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Created
            </th>
            {renderActionSheet && (
              <th className='text-left font-bold py-2 border-b border-t border-secondary w-12' />
            )}
          </tr>
        </thead>
        <tbody>
          {logics.map((logic) => (
            <tr
              key={logic.id}
              className='hover:bg-dark-gray cursor-pointer h-full'
              onClick={() => onClick(logic)}
            >
              <td className='text-left font-bold pl-3 py-3 border-b border-black-001 pr-3 truncate min-w-100'>
                {logic.name}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate whitespace-pre-line'>
                {MessageTriggerUtils.GetDisplayLabel(logic.trigger)}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate whitespace-pre-line'>
                <div className='flex items-center gap-2'>
                  <MessageTemplateDistributionChannelIcon
                    channel={logic.template.type}
                    className='w-5 h-5 fill-current'
                  />
                  {MessageTargetUtils.GetDisplayLabel(logic.target)}
                </div>
              </td>
              <td
                className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate whitespace-pre-line'
                onClick={(e) => e.stopPropagation()}
              >
                <SwitcherControlled
                  name={`message-logic-${logic.id}-status`}
                  className=''
                  checked={
                    logic.status ===
                    EnumsMessageLogicStatus.MessageLogicStatusActive
                  }
                  onChange={(checked: boolean) =>
                    checked ? onActivate(logic) : onDeactivate(logic)
                  }
                />
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                {format(new Date(logic.createdAt), 'MMM dd, yyyy')}
              </td>
              {renderActionSheet && (
                <td className='h-px p-0 border-b border-black-001'>
                  <div className='h-full flex items-center justify-center'>
                    {renderActionSheet(logic)}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Component() {
  const { campaign, logics } = useLoaderData<typeof clientLoader>();
  const { revalidate } = useRevalidator();

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const triggerMessageLogicEditorModal = useTriggerMessageLogicEditorV2();

  const handleCreate = async () => {
    triggerMessageLogicEditorModal({
      campaign,
      onSubmit: async () => {
        revalidate();
      },
    });
  };

  const handleEdit = (logic: DtoMessageLogicV2) => {
    triggerMessageLogicEditorModal({
      campaign,
      logic,
      onSubmit: async () => {
        revalidate();
      },
    });
  };

  const handleActivate = async (logic: DtoMessageLogicV2) => {
    await apiService.message.activateLogic(logic.id);
    revalidate();
  };

  const handleDeactivate = async (logic: DtoMessageLogicV2) => {
    await apiService.message.deactivateLogic(logic.id);
    revalidate();
  };

  const handleDuplicate = async (logic: DtoMessageLogicV2) => {
    await apiService.message.duplicateLogic(logic.id);
    revalidate();
  };

  const handleDelete = async (logic: DtoMessageLogicV2) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Delete</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to delete this logic? This action cannot be
            undone.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await apiService.message.deleteLogic(logic.id);
    revalidate();
  };

  useEffectOnce(() => {
    const logicId = getQueryParam('logic-id');
    const logic = logics.find((l) => l.id === logicId);
    if (!logic) return;
    handleEdit(logic);
  });

  return (
    <AdminView className='text-white w-full h-full p-12'>
      <header className='text-3xl font-medium'>
        <Link
          to={'/admin/messages/campaigns'}
          className='text-icon-gray hover:text-white hover:underline'
        >
          Drip Campaigns
        </Link>{' '}
        \ <span className='text-white'>{campaign.name}</span>
      </header>

      <main className='mt-10 w-full flex flex-col gap-8'>
        <div className='w-full'>
          <h2 className='text-2xl font-bold'>Details</h2>

          <div className='mt-4 flex flex-col gap-3'>
            <div className='flex items-center gap-1'>
              <span className='text-icon-gray'>Trigger (T0):</span>
              <span className='text-white'>{campaign.description}</span>
            </div>
          </div>
        </div>

        <MessageLogicsTable
          titleAccessory={
            <button
              type='button'
              className='btn flex justify-center items-center text-primary gap-1 text-sm font-medium hover:underline'
              onClick={handleCreate}
            >
              + Add New Logic
            </button>
          }
          logics={logics}
          onClick={handleEdit}
          onActivate={handleActivate}
          onDeactivate={handleDeactivate}
          renderActionSheet={(logic) => (
            <MessageLogicMenu
              onEdit={() => handleEdit(logic)}
              onDuplicate={() => handleDuplicate(logic)}
              onDelete={() => handleDelete(logic)}
            />
          )}
        />
      </main>
    </AdminView>
  );
}
