import { FormProvider, useForm } from 'react-hook-form';

import {
  type DtoMessageLogicV2,
  EnumsMessageTarget,
  EnumsMessageTriggerType,
} from '@lp-lib/api-service-client/public';

import { MessageConditionEditor } from '../MessageCondition';
import { MessageTargetEditor } from '../MessageTarget';
import { MessageTriggerEditor } from '../MessageTrigger';
import { type MessageCampaignV2 } from '../Shared';
import { MessageTemplateFields } from '../Template';
import { MessageLogicEditorLayout } from './MessageLogicEditorLayout';
import { type MessageLogicFormData } from './Shared';
import { MessageLogicUtils } from './utils';

export function CancelOfferAcceptedLogicEditor(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;
}) {
  const { campaign, logic, onCancel, onSubmit } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: MessageLogicUtils.MakeDefaultValues({
      logic,
      defaultValues: {
        target: EnumsMessageTarget.MessageTargetOrgAdmins,
      },
    }),
  });

  return (
    <FormProvider {...form}>
      <MessageLogicEditorLayout
        campaign={campaign}
        logic={logic}
        onCancel={onCancel}
        onSubmit={onSubmit}
        trigger={
          <MessageTriggerEditor
            config={{
              types: [
                EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
                EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
              ],
            }}
          />
        }
        target={
          <MessageTargetEditor
            supportedTargets={[EnumsMessageTarget.MessageTargetOrgAdmins]}
          />
        }
        condition={
          <MessageConditionEditor
            vars={{
              didAcceptOffer: true,
              productId: '',
            }}
          />
        }
        template={
          <MessageTemplateFields
            vars={{
              recipientName: 'John',
              productName: 'Unlimited Access',
              offerEndDate: 'Monday, January 2',
            }}
          />
        }
      />
    </FormProvider>
  );
}
