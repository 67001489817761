import { FormProvider, useForm } from 'react-hook-form';

import {
  type DtoMessageLogicV2,
  EnumsMessageTarget,
  EnumsMessageTriggerType,
} from '@lp-lib/api-service-client/public';

import { MessageConditionEditor } from '../MessageCondition';
import { MessageTargetEditor } from '../MessageTarget';
import { MessageTriggerEditor } from '../MessageTrigger';
import { type MessageCampaignV2 } from '../Shared';
import { MessageTemplateFields } from '../Template';
import { MessageLogicEditorLayout } from './MessageLogicEditorLayout';
import { type MessageLogicFormData } from './Shared';
import { MessageLogicUtils } from './utils';

const PURCHASED_LINK =
  'https://lunapark.com/checkout/pay?pack-id=8efb7dff-8da9-477f-8515-a9552ad8661a&headcount=15&game-pack-repeat-purchase=enabled';

const SUBSCRIPTION_PURCHASE_LINK =
  'https://lunapark.com/onboarding/billing?product-id=01HYGTWNH5PFZ3362XMPCWAFN8&price-id=6d09e258-b174-499f-b67d-d2facf372486&headcount=15&trial=true';

export function OTPStartedLogicEditor(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;
}) {
  const { campaign, logic, onCancel, onSubmit } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: MessageLogicUtils.MakeDefaultValues({ logic }),
  });

  return (
    <FormProvider {...form}>
      <MessageLogicEditorLayout
        campaign={campaign}
        logic={logic}
        onCancel={onCancel}
        onSubmit={onSubmit}
        trigger={
          <MessageTriggerEditor
            config={{
              types: [
                EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
                EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
              ],
            }}
          />
        }
        target={
          <MessageTargetEditor
            supportedTargets={[EnumsMessageTarget.MessageTargetMember]}
          />
        }
        condition={
          <MessageConditionEditor
            vars={{
              isInvoiceSent: false,
              hasSubscriptionIntent: false,
              isPurchasePending: true,
              productId: '',
              purchasedCount: 0,
              maxPlayersCount: 0,
            }}
          />
        }
        template={
          <MessageTemplateFields
            vars={{
              recipientName: 'John',
              gamePackName: `Who's Who?`,
              purchaseLink: PURCHASED_LINK,
              subscriptionPurchaseLink: SUBSCRIPTION_PURCHASE_LINK,
              notificationSettingsLink:
                'https://lunapark.com/settings/notifications',
            }}
          />
        }
      />
    </FormProvider>
  );
}
