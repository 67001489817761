import { FormProvider, useForm } from 'react-hook-form';

import {
  type DtoMessageLogicV2,
  EnumsMessageTarget,
  EnumsMessageTriggerType,
} from '@lp-lib/api-service-client/public';

import { MessageConditionEditor } from '../MessageCondition';
import { MessageTargetEditor } from '../MessageTarget';
import { MessageTriggerEditor } from '../MessageTrigger';
import { type MessageCampaignV2 } from '../Shared';
import { MessageTemplateFields } from '../Template';
import { MessageLogicEditorLayout } from './MessageLogicEditorLayout';
import { type MessageLogicFormData } from './Shared';
import { MessageLogicUtils } from './utils';

export function SessionEndedLogicEditor(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;
}) {
  const { campaign, logic, onCancel, onSubmit } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: MessageLogicUtils.MakeDefaultValues({ logic }),
  });

  return (
    <FormProvider {...form}>
      <MessageLogicEditorLayout
        campaign={campaign}
        logic={logic}
        onCancel={onCancel}
        onSubmit={onSubmit}
        trigger={
          <MessageTriggerEditor
            config={{
              types: [
                EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
                EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
              ],
            }}
          />
        }
        target={
          <MessageTargetEditor
            supportedTargets={[EnumsMessageTarget.MessageTargetMember]}
          />
        }
        condition={
          <MessageConditionEditor
            vars={{
              productId: '',
              maxPlayers: 10,
              didOrganize: false,
            }}
          />
        }
        template={
          <MessageTemplateFields
            vars={{
              recipientName: 'John',
              organizerName: 'Jane',
              gamePackName: 'General Trivia',
              featuredGameName: `Who's Who`,
              featuredGameCover:
                'https://assets.golunapark.com/images/19647/82a39e509c7d4714a5275480715e0f82_md.jpg',
              featuredGameLink:
                'https://lunapark.com/events/create?game-pack-id=8efb7dff-8da9-477f-8515-a9552ad8661a',
            }}
          />
        }
      />
    </FormProvider>
  );
}
