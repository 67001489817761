import pluralize from 'pluralize';

import {
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
  type ModelsMessageTriggerTimeOffset,
} from '@lp-lib/api-service-client/public';

import { assertExhaustive } from '../../../utils/common';
import { MESSAGE_TRIGGER_EVENT_CONFIG } from '../MessageTrigger';

export class MessageTriggerUtils {
  static GetDisplayLabel(trigger: ModelsMessageTrigger): string {
    switch (trigger.type) {
      case EnumsMessageTriggerType.MessageTriggerTypeTimeOffset:
      case null:
      case undefined:
        return MessageTriggerUtils.GetTimeOffsetLabel(trigger.timeOffset);
      case EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset:
        return MessageTriggerUtils.GetWeekdaysOffsetLabel(
          trigger.weekdaysOffset
        );
      case EnumsMessageTriggerType.MessageTriggerTypeEventDriven:
        if (!trigger.eventName) return '';
        return MESSAGE_TRIGGER_EVENT_CONFIG[trigger.eventName].label;
      default:
        assertExhaustive(trigger.type);
        return '';
    }
  }

  static GetTimeOffsetLabel(offset?: ModelsMessageTriggerTimeOffset | null) {
    if (!offset) return '';

    const arr = [];
    if (offset.days > 0) {
      arr.push(pluralize('day', offset.days, true));
    }
    if (offset.hours > 0) {
      arr.push(pluralize('hour', offset.hours, true));
    }
    if (arr.length === 0 || offset.minutes > 0) {
      arr.push(pluralize('min', offset.minutes, true));
    }

    return `${arr.join(' ')} \n ${offset.direction} the start`;
  }

  static GetWeekdaysOffsetLabel(
    offset?: ModelsMessageTriggerTimeOffset | null
  ) {
    if (!offset) return '';

    const arr = [];
    if (offset.days > 0) {
      arr.push(pluralize('weekday', offset.days, true));
    }
    if (offset.hours > 0) {
      arr.push(pluralize('hour', offset.hours, true));
    }
    if (arr.length === 0 || offset.minutes > 0) {
      arr.push(pluralize('min', offset.minutes, true));
    }

    return `${arr.join(' ')} \n ${offset.direction} the start`;
  }
}
