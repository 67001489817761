import { FormProvider, useForm } from 'react-hook-form';

import {
  type DtoMessageLogicV2,
  EnumsMessageTarget,
  EnumsMessageTriggerType,
} from '@lp-lib/api-service-client/public';

import { MessageConditionEditor } from '../MessageCondition';
import { MessageTargetEditor } from '../MessageTarget';
import { MessageTriggerEditor } from '../MessageTrigger';
import { type MessageCampaignV2 } from '../Shared';
import { MessageTemplateFields } from '../Template';
import { MessageLogicEditorLayout } from './MessageLogicEditorLayout';
import { type MessageLogicFormData } from './Shared';
import { MessageLogicUtils } from './utils';

export function ProgramInstalledLogicEditor(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;
}) {
  const { campaign, logic, onCancel, onSubmit } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: MessageLogicUtils.MakeDefaultValues({ logic }),
  });

  return (
    <FormProvider {...form}>
      <MessageLogicEditorLayout
        campaign={campaign}
        logic={logic}
        onCancel={onCancel}
        onSubmit={onSubmit}
        trigger={
          <MessageTriggerEditor
            config={{
              types: [
                EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
                EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
              ],
            }}
          />
        }
        target={
          <MessageTargetEditor
            supportedTargets={[EnumsMessageTarget.MessageTargetMember]}
          />
        }
        condition={
          <MessageConditionEditor
            vars={{
              programId: '',
              isActive: true,
              membersCount: 10,
            }}
          />
        }
        template={
          <MessageTemplateFields
            vars={{
              recipientName: 'John',
              programSettingsLink: 'https://lunapark.com',
              channelName: '#lunapark',
              nextRoundDate: 'Wed, Apr 24th',
            }}
          />
        }
      />
    </FormProvider>
  );
}
