import {
  type DtoMessageLogicV2,
  EnumsMessageDistributionChannel,
  EnumsMessageTarget,
  EnumsMessageTriggerTimeOffsetDirection,
  EnumsMessageTriggerType,
} from '@lp-lib/api-service-client/public';

import { type MessageLogicFormData } from './Shared';

export class MessageLogicUtils {
  static MakeDefaultValues(props?: {
    logic?: DtoMessageLogicV2;
    defaultValues?: Partial<MessageLogicFormData>;
  }) {
    const { logic, defaultValues } = props || {};

    return {
      name: logic?.name || defaultValues?.name || '',
      target:
        logic?.target ||
        defaultValues?.target ||
        EnumsMessageTarget.MessageTargetMember,
      trigger: logic?.trigger ||
        defaultValues?.trigger || {
          type: EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
          timeOffset: {
            days: 0,
            hours: 0,
            minutes: 0,
            direction:
              EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter,
          },
        },
      condition: logic?.condition ||
        defaultValues?.condition || {
          expression: '',
        },
      template: logic?.template || {
        type: EnumsMessageDistributionChannel.MessageDistributionChannelEmail,
      },
    };
  }
}
